import $ from "jquery";
import "jquery.easing";

// Menu
$(function () {
  let menuHover = false;
  let menuShown = true;

  const menuHideScroll = 20; // Pixels to scroll before menu hides
  const menuSpeed = 240; // Menu animation speed
  const menuHideDelay = 250; // Delay before hiding menu

  const drawMenu = function () {
    const scrollPosition = $(window).scrollTop();
    const showMenu = menuHover || scrollPosition < menuHideScroll;

    if (showMenu && !menuShown) {
      $("#wee").find("ul").animate(
        {
          left: "40px"
        },
        { queue: false, duration: menuSpeed, easing: "easeOutExpo" }
      );
      menuShown = true;
    }
    if (!showMenu && menuShown) {
      $("#wee").find("ul").animate(
        {
          left: "-210px"
        },
        { queue: false, duration: menuSpeed, easing: "easeInOutQuad" }
      );
      menuShown = false;
    }
  };

  drawMenu();
  $(window).scroll(drawMenu);
  $(window).resize(drawMenu);

  $("#wee").hover(
    function () {
      menuHover = true;
      drawMenu();
    },
    function () {
      menuHover = false;
      setTimeout(function () {
        drawMenu();
      }, menuHideDelay);
    }
  );
});
