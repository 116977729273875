import $ from "jquery";
import "jquery-cycle";

// Slideshow
$(function () {
  // cycle images
  $(".images").cycle({
    fx: "scrollVert",
    speed: 250,
    next: ".next",
    prev: ".prev",
    timeout: 0
  });

  $(".slideshow").cycle();
});
