import $ from "jquery";

// News
$(function () {
  const fadeNewsScroll = 50; // Pixels to scroll before menu hides
  const initialDelay = 300;
  const delay = 150;

  // Animate in news
  const $newsItems = $("#news .item");

  if ($newsItems.length > 0) {
    $newsItems.each(function (i) {
      setTimeout(
        () => {
          $(this).addClass("shown");
        },
        i * delay + initialDelay
      );
    });

    const fadeNews = function () {
      const scrollPosition = $(window).scrollTop();

      if (scrollPosition < fadeNewsScroll) {
        $newsItems.removeClass("faded");
      } else {
        $newsItems.addClass("faded");
      }
    };
    fadeNews();
    $(window).scroll(fadeNews);
    $(window).resize(fadeNews);
  }
});
